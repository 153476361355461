.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --anw-offcanvas-zindex: 1045;
  --anw-offcanvas-width: 300px !important;
  --anw-offcanvas-height: 30vh;
  --anw-offcanvas-padding-x: 0;
  --anw-offcanvas-padding-y: 0;
  --anw-offcanvas-color: ;
  --anw-offcanvas-bg: #f0f0f0;
  --anw-offcanvas-border-width: 1px;
  --anw-offcanvas-border-color: var(--anw-border-color-translucent);
  --anw-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 467.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--anw-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--anw-offcanvas-color);
    visibility: hidden;
    background-color: var(--anw-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--anw-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 467.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 467.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--anw-offcanvas-width);
    border-right: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 467.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--anw-offcanvas-width);
    border-left: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 467.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 467.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-top: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 467.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 467.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 468px) {
  .offcanvas-sm {
    --anw-offcanvas-height: auto;
    --anw-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--anw-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--anw-offcanvas-color);
    visibility: hidden;
    background-color: var(--anw-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--anw-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--anw-offcanvas-width);
    border-right: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--anw-offcanvas-width);
    border-left: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-top: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --anw-offcanvas-height: auto;
    --anw-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1023.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--anw-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--anw-offcanvas-color);
    visibility: hidden;
    background-color: var(--anw-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--anw-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1023.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--anw-offcanvas-width);
    border-right: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--anw-offcanvas-width);
    border-left: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-top: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .offcanvas-lg {
    --anw-offcanvas-height: auto;
    --anw-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--anw-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--anw-offcanvas-color);
    visibility: hidden;
    background-color: var(--anw-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--anw-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--anw-offcanvas-width);
    border-right: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--anw-offcanvas-width);
    border-left: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-top: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --anw-offcanvas-height: auto;
    --anw-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--anw-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--anw-offcanvas-color);
    visibility: hidden;
    background-color: var(--anw-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--anw-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--anw-offcanvas-width);
    border-right: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--anw-offcanvas-width);
    border-left: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--anw-offcanvas-height);
    max-height: 100%;
    border-top: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1600px) {
  .offcanvas-xxl {
    --anw-offcanvas-height: auto;
    --anw-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--anw-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--anw-offcanvas-color);
  visibility: hidden;
  background-color: var(--anw-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--anw-offcanvas-box-shadow);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--anw-offcanvas-width);
  border-right: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--anw-offcanvas-width);
  border-left: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--anw-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--anw-offcanvas-height);
  max-height: 100%;
  border-top: var(--anw-offcanvas-border-width) solid var(--anw-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.6;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--anw-offcanvas-padding-y) var(--anw-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--anw-offcanvas-padding-y) * .5) calc(var(--anw-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--anw-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--anw-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--anw-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.375;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--anw-offcanvas-padding-y) var(--anw-offcanvas-padding-x);
  overflow-y: auto;
}